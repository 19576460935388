import React from 'react';
import PropTypes from 'prop-types';
import {Box, styled} from '@mui/system';
import {Grid} from '@mui/material';
import GenericSource from '../sources/GenericSource';
import AIMessage from './AIMessage';
import YoutubeSource from '../sources/YoutubeSource';
import Typography from '@mui/material/Typography';

function AIContextMessage({className, context}) {
  const renderSourceComponent = (fragment) => {
    if (fragment?.source?.type == 'YOUTUBE') {
      return <YoutubeSource fragment={fragment} sx={{height: '100%'}}/>;
    } else if (fragment?.source?.type == 'PDF') {
      const metadataRenderer = (metadata) => (
        <Typography variant="body2">Found on page: {metadata?.page}</Typography>
      );
      return (
        <GenericSource
          fragment={fragment}
          metadataRenderer={metadataRenderer}
          sx={{height: '100%'}}/>
      );
    }

    // If none of the above applies, return a default
    return <GenericSource fragment={fragment} sx={{height: '100%'}} />;
  };


  return (
    <AIMessage className={className}>
      <Box
        sx={{
          width: '100%',
        }}>
        <Grid container spacing={2}>
          {context?.map((fragment, i) =>
            <Grid item xs={12} lg={6} key={i}>
              {renderSourceComponent(fragment)}
            </Grid>,
          )}
        </Grid>

      </Box>
    </AIMessage>
  );
}

AIContextMessage.propTypes = {
  className: PropTypes.string,
  context: PropTypes.arrayOf(PropTypes.object),
};

export default styled(AIContextMessage)({
  width: '100%',
});
