import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/system';
import {CircularProgress} from '@mui/material';
import Card from '../ui/Card';
import AIMessage from './AIMessage';

function AITextMessage({className, message}) {
  return (
    <AIMessage
      className={className}>
      <Card
        sx={{
          width: '100%',
          whiteSpace: 'pre-wrap',
          backgroundColor: (t) => t.palette.primary.main,
          color: (t) => t.palette.primary.contrastText,
          p: 2,
        }}>
        {!message && <CircularProgress color="primary" size={15}></CircularProgress>}
        {message}
      </Card>
    </AIMessage>
  );
}

AITextMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
};

export default styled(AITextMessage)({
  width: '100%',
});
