import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ThemeProvider} from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import BaseView from './views/BaseView';
import ErrorView from './views/ErrorView';
import LoginView from './views/LoginView';
import AgentPage from './views/pages/AgentPage';


function App() {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LoginView />,
    },
    {
      path: '/',
      element: <BaseView />,
      errorElement: <ErrorView />,
      children: [
        {
          path: '/',
          element: <AgentPage />,
        },
      ],
    },
  ]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <RouterProvider router={router} />
    </ThemeProvider>

  );
}

export default App;
