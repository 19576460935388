import {createTheme} from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: '0.8rem',
    },
    body1: {
      marginBottom: '10px',
      fontSize: '0.9rem',
      lineHeight: '1.5rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.15rem',
    },
  },
  palette: {
    background: {
      default: '#FFFFFF',
      grey: '#F5F5F5',
    },
    warning: {
      main: '#ff6600',
    },
    primary: {
      main: '#00447E',
    },
    secondary: {
      main: '#F4F2F0',
    },
    tertiary: {
      main: '#33D398',
    },
    text: {
      dark: '#000000',
      primary: '#666666',
      white: '#FFFFFF',
    },
  },
});

export default theme;
