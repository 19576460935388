import * as React from 'react';
import Chat from '../../common/Chat';
import {Box} from '@mui/system';
import {useFetchGet} from '../../common/hooks/api';
import {API_URI} from '../../common/globals';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useState} from 'react';

export default function AgentPage() {
  const {
    data: agents,
  } = useFetchGet(
    `${API_URI}/v1/agent/`,
    [],
    true,
  );
  const [agent, setAgent] = useState('rag');
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}>
        <FormControl>
          <InputLabel id="agent-selector">Agent</InputLabel>
          <Select
            labelId="agent-selector"
            value={agent}
            label="Agent"
            onChange={(e) => setAgent(e.target.value)}
          >
            <MenuItem value="rag">Retrieval Augmented Generation</MenuItem>
            {agents?.map((agent) => (
              <MenuItem key={agent.uuid} value={agent.uuid}>{agent.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Chat
        agentId={agent}></Chat>
    </Box>
  );
}
