import {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../AuthContext';
import {getAuthenticatedFetch} from './api';
import ndjsonStream from 'can-ndjson-stream';
import {AGENT_URI} from '../globals';

export const getChatUrlForAgentId = (agentId) => {
  // We have a few special agent types like RAG. This method makes it possible to pass
  // these in as an ID and have them returned.
  if (agentId == 'rag') {
    return `${AGENT_URI}/rag`;
  }

  // TODO: this created an issue with trailing slashes redirecting to an
  //  unsafe URL. This was now fixxed by adding a trailing / but should be fixxed
  //  at the API side.
  return `${AGENT_URI}/chat/${agentId}/`;
};

export const useAgent = (
  agentId,
  conversationId,
  requiresAuth = false,
  callback,
) => {
  const auth = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const controller = new AbortController();
    const inner = async () => {
      setIsPending(true);
      let client;
      if (requiresAuth) {
        client = getAuthenticatedFetch(auth.token);
      } else {
        client = fetch;
      }

      const res = await client(
        getChatUrlForAgentId(agentId),
        {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            messages: messages,
            conversation: conversationId?.toString('base64'),
          }),
          signal: controller.signal,
        },
      );

      if (!res.ok) {
        // Check if we encountered an error without starting the stream
        setIsPending(false);
        setError({
          status: res.status,
          message: res.statusText,
        });
        return null;
      }

      // Stream the result event by event
      const ndJSONStreamReader = ndjsonStream(res.body).getReader();
      let result;
      while (!result || !result.done) {
        result = await ndJSONStreamReader.read();
        if (result.type == 'error') {
          // Not all errors will be http errors, cause some might occur during the stream.
          // Here we catch those
          setError({
            message: result.value.content,
          });
        } else if (result.value) {
          callback(result.value);
        }
      }
      setIsPending(false);
    };


    if (messages?.length > 0) {
      setError(null);
      inner();
    }

    // Return the abort controller such that we stop the stream when we unmount this component
    return () => {
      controller.abort();
    };
  }, [messages, agentId, conversationId]);

  const clear = () => {
    setMessages([]);
    setError(null);
  };

  return {
    setMessages,
    isPending,
    error,
    clear,
  };
};
