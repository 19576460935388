import * as React from 'react';
import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../common/AuthContext';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Card from '../common/ui/Card';
import {Alert, CardContent, CardHeader, TextField, Button, Typography, Grid} from '@mui/material';
import {useTheme} from '@mui/system';

export default function LoginView() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .required('Dit veld is verplicht'),
      password: Yup.string()
        .required('Dit veld is verplicht'),
    }),
    onSubmit: (values, {setStatus, setSubmitting}) => {
      const inner = async () => {
        const token = await auth.login(values.username, values.password);
        if (!token) {
          setStatus('We konden je niet inloggen.');
          setSubmitting(false);
        }
      };

      inner();
    },
  });

  useEffect(() => {
    if (auth.token) {
      navigate('/');
    }
  }, [auth]);

  return (
    <Grid container sx={{
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center', p: 2,
    }}>
      <Grid
        item
        xs={12}
        sm={8}
        lg={4}
        xl={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Card sx={{width: '100%'}}>
          <CardHeader>
            <Typography variant="h3">Login</Typography>
          </CardHeader>
          <CardContent>
            {(formik.isSubmitting) &&
            <Alert severity="info">We are logging you in</Alert>}
            {!formik.isSubmitting && <>
              <form
                onSubmit={formik.handleSubmit}
                style={{display: 'flex', flexDirection: 'column', gap: theme.spacing(3)}}>
                {formik.status && <Alert severity="error">{formik.status}</Alert>}
                <TextField
                  id="username"
                  label="Gebruikersnaam"
                  variant="standard"
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  error={formik.touched.username && !!formik.errors.username}
                  helperText={formik.touched.username && formik.errors.username}/>
                <TextField
                  id="password"
                  label="Wachtwoord"
                  type="password"
                  variant="standard"
                  size="medium"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.touched.password && !!formik.errors.password}
                  helperText={formik.touched.password && formik.errors.password}/>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{boxShadow: 'none'}}>
                  Login
                </Button>
              </form>
            </>}
          </CardContent>
        </Card>
      </Grid>
    </Grid>

  );
}
