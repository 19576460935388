import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Card from '../ui/Card';
import {CardActions, Chip, CircularProgress, Tooltip} from '@mui/material';
import {Box, styled} from '@mui/system';
import ReactPlayer from 'react-player/youtube';

function YoutubeSource({fragment, className}) {
  const [loading, setLoading] = useState(true);
  return (
    <Card className={className}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          position: 'relative',
        }}>
        <ReactPlayer
          url={fragment?.source?.url}
          width='100%'
          height="100%"
          config={{
            youtube: {
              playerVars: {
                controls: 0,
                showinfo: 0,
                start: fragment?.metadata?.start,
              },
            },
          }}
          onReady={() => setLoading(false)}
        />
        {loading && <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'block',
            transform: 'translate(-50%, -50%)',
          }}>
          <CircularProgress
            color="primary"
            size={40}/>
        </Box>}
        <CardActions
          sx={{
            justifyContent: 'space-between',
            position: 'absolute',
            zIndex: 10000000,
            bottom: 0,
            left: 0,
            width: '100%',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: (t) => t.spacing(1),
            }}>
            {loading && <Chip
              label={fragment?.source?.type}
              color="primary"
              sx={{
                maxWidth: '130px',
              }}></Chip>}
            {fragment?.source?.collection &&
              <Tooltip title={fragment?.source?.collection?.description}>
                <Chip
                  label={fragment?.source?.collection?.name}
                  color="secondary"
                  sx={{
                    maxWidth: '150px',
                  }}></Chip>
              </Tooltip>
            }
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: (t) => t.spacing(1),
            }}>
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
}

YoutubeSource.propTypes = {
  fragment: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default styled(YoutubeSource)({});
