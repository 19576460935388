import React, {useCallback, useEffect, useState} from 'react';
import AIMessage from './AITextMessage';
import {Box, styled} from '@mui/system';
import HumanMessage from './HumanTextMessage';
import {Alert, TextField, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import {useAgent} from '../hooks/agents';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AgentHeader from './AgentHeader';
import ContextMessage from './AIContextMessage';
import Feedback from './Feedback';

function Chat({className, agentId}) {
  const [history, setHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [conversationUUID, setConversationUUID] = useState(null);

  const submit = useCallback(() => {
    const message = {
      type: 'human',
      content: userInput,
    };
    const agentMessages = [...history, message];
    // First update the local history so we update the UI, subsequently, update the messages in
    // for the agent hook such that we'll get in new streaming data.
    setHistory(agentMessages);
    setMessages(_.filter(agentMessages, (e) => _.includes(['ai', 'human'], e.type)));
    setUserInput('');
  }, [history, userInput]);

  useEffect(() => {
    // If we switch agents, make sure to clean up the history and initiate a new conversation.
    setHistory([]);
    setConversationUUID(crypto.randomUUID());
  }, [agentId]);

  const handleAnswerEvent = (event) => {
    setHistory((prevState) => {
      if (_.last(prevState)?.type != 'ai') {
        // If the last message is not of the type AI, we'll need to create it before we can store
        // any content into it
        const newMessage = {
          type: 'ai',
          content: event.content,
        };
        return [
          ...prevState,
          newMessage,
        ];
      } else {
        // Otherwise, if the last message is already of type AI, it is merely a matter of updating
        // the last element of the array.
        return prevState.map((message, i, prev) => {
          if (prev.length - 1 === i) {
            // This is the last message, hence the one we'll need to update
            return {
              ...message,
              content: message.content + event.content,
            };
          }
          return message;
        });
      }
    });
  };

  const handleContextEvent = (event) => {
    setHistory((prevState) => {
      const contextMessage = {
        type: 'context',
        content: event.content,
      };
      return [...prevState, contextMessage];
    });
  };


  const callBack = (event) => {
    if (event.type == 'context') {
      handleContextEvent(event);
    }
    if (event.type == 'answer') {
      handleAnswerEvent(event);
    }
  };

  const {
    setMessages,
    isPending,
    error,
  } = useAgent(
    agentId,
    conversationUUID,
    true,
    callBack,
  );

  return (
    <Box className={className}>
      {error && <Alert
        severity="error"
        sx={{mb: 2}}>
        Something went wrong.
      </Alert>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: (t) => t.spacing(2),
        }}>
        <AgentHeader
          agentId={agentId}></AgentHeader>
        {history?.map((message, i) => {
          if (message.type == 'human') {
            return <HumanMessage key={i} message={message.content} />;
          } else if (message.type == 'ai') {
            return <AIMessage key={i} message={message.content} />;
          } else if (message.type == 'context') {
            return <ContextMessage key={i} context={message.content}/>;
          }
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
        }}>
        <TextField
          variant="outlined"
          color="secondary"
          value={userInput}
          disabled={isPending}
          multiline
          maxRows={4}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyUp={(e) => {
            if (e.code == 'Enter' && e.shiftKey) {
              e.preventDefault();
              submit();
            }
          }}
          label="Ask something..."
          sx={{flexGrow: 1}}></TextField>
        <IconButton
          disabled={isPending}
          color="primary"
          onClick={submit}>
          <SendIcon></SendIcon>
        </IconButton>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Typography variant="subtitle1">
          How do you like this conversation?
        </Typography><Feedback
          conversationId={conversationUUID}
          disabled={history?.length == 0}></Feedback>
      </Box>
    </Box>
  );
}

Chat.propTypes = {
  className: PropTypes.string,
  agentId: PropTypes.string,
};

export default styled(Chat)({
  width: '100%',
});
