import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Stack} from '@mui/system';
import {CardActions, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useFetchGet} from '../hooks/api';
import {API_URI} from '../globals';
import Divider from '@mui/material/Divider';
import AITextMessage from './AITextMessage';

function AgentHeader({agentId}) {
  const {data, setUrl, setData} = useFetchGet(
    null,
    null,
    true,
  );

  useEffect(() => {
    if (agentId && agentId != 'rag') {
      setUrl(`${API_URI}/v1/agent/${agentId}/`);
    } else if (agentId == 'rag') {
      setUrl(null);
      setData({
        name: 'AI Assistant',
        description: 'Hi, I\'m your retrieval augmented generation agent. I\'m specialized in ' +
          'answering questions based on all knowledge you\'ve fed me. This makes me highly ' +
          'reliable but maybe less generic in the tasks I can do for you.',
      });
    }
  }, [agentId]);

  const functions = data?.openapi_functions || [];
  const filteredFunctions = functions.filter((functionality) => {
    return !!functionality.display_description;
  });

  return (
    <>
      <AITextMessage
        message={
          <>
            <Typography gutterBottom variant="h4">
              {data?.name}
            </Typography>
            <Typography variant="body2">
              {data?.description || 'No description available'}
            </Typography>
          </>
        }/>
      {
        filteredFunctions.length > 0 && <AITextMessage
          message={
            <>
              <Typography>
                I can help you with the following tasks:
              </Typography>
              <CardActions>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  sx={{width: '100%'}}
                  divider={<Divider orientation="vertical" flexItem/>}>
                  {filteredFunctions.map((functionality) => (
                    <Tooltip
                      arrow
                      title={functionality.display_description}
                      key={functionality.name}>
                      <Typography
                        variant="body2"
                        sx={{textAlign: 'center', pl: 1, pr: 1}}
                      >{functionality?.display_name}</Typography>
                    </Tooltip>
                  ))}
                </Stack>
              </CardActions>
            </>
          }/>
      }
      {data?.collections?.length > 0 && <AITextMessage
        message={
          <>
            <Typography>
              To come up with good answers, I can consult a few collections.
              I&apos;ve listed them below.
            </Typography>
            <CardActions>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                sx={{width: '100%'}}
                divider={<Divider orientation="vertical" flexItem/>}>
                {data?.collections?.map((collection) => (
                  <Tooltip
                    arrow
                    title={collection.description}
                    key={collection.uuid}>
                    <Typography
                      variant="body2"
                      sx={{textAlign: 'center', pl: 1, pr: 1}}>{collection?.name}</Typography>
                  </Tooltip>
                ))}
              </Stack>
            </CardActions>
          </>
        }/>}
    </>

  );
}

AgentHeader.propTypes = {
  agentId: PropTypes.string.isRequired,
};

export default AgentHeader;
