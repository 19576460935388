import React from 'react';
import PropTypes from 'prop-types';
import {Box, styled} from '@mui/system';
import {Avatar} from '@mui/material';

function AIMessage({className, children}) {
  return (
    <Box className={className}>
      <Box
        sx={{
          width: '100%',
          pr: {
            xs: '25px',
            md: '45%',
          },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'top',

        }}>
        <Avatar
          alt="AI"
          src="/ai.png"
          sx={{mr: 1, mt: 1}}></Avatar>
        <Box
          sx={{
            flexGrow: 1,
          }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

AIMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node, PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default styled(AIMessage)({
  width: '100%',
});
