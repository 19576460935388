import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Link} from 'react-router-dom';
import {AuthContext} from '../AuthContext';
import {API_URI} from '../globals';

function OpenDocumentButton({source}) {
  const button = (
    <IconButton>
      <OpenInNewIcon />
    </IconButton>
  );
  const {token} = useContext(AuthContext);

  if (source.is_external) {
    return <Link
      to={source?.url}
      target="_blank">
      {button}
    </Link>;
  } else {
    return <Link
      to={`${API_URI}${source?.url}?token=${token}`}
      target="_blank">
      {button}
    </Link>;
  }
}

OpenDocumentButton.propTypes = {
  source: PropTypes.object,
};

export default OpenDocumentButton;
