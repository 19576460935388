import React from 'react';
import {Card as MuiCard} from '@mui/material';
import {styled} from '@mui/system';
import PropTypes from 'prop-types';

function Card({children, className}) {
  return (
    <MuiCard className={className}>
      {children}
    </MuiCard>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
};

export default styled(Card)({});
