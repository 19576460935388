import * as React from 'react';
import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import Cookies from 'js-cookie';

import {AuthContext} from './AuthContext.js';
import {API_URI} from './globals';
import _ from 'lodash';


export default function AuthenticatedApp({children}) {
  const COOKIE_NAME = 'token';
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(Cookies.get('token'));
  const [authErrors, setAuthErrors] = useState(null);

  const login = async (username, password) => {
    const res = await fetch(
      `${API_URI}/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username, password}),
      },
    );

    if (res.ok) {
      const data = await res.json();
      setToken(data.token);
      Cookies.set(COOKIE_NAME, data.token);
      return data.token;
    } else {
      setAuthErrors({
        status: res.status,
        message: res.statusText,
      });
      return false;
    }
  };

  const logout = (data) => {
    Cookies.remove(COOKIE_NAME);
    setToken(null);
    setUser(null);
    setAuthObject((old) => {
      const result = _.cloneDeep(old);
      result.user = null;
      result.token = null;
      return result;
    });
  };

  // We can only define the general Auth object after we've defined our login and logout methods
  const [authObject, setAuthObject] = useState({
    user,
    token,
    authErrors,
    login,
    logout,
  });

  useEffect(() => {
    // When token changes, re-fetch the information for our new user
    const fetchUser = async () => {
      // We use a nested async function to deal with the fact that React's useEffect API
      // behaves differently if the first argument returns a function (which async functions do)
      const res = await fetch(
        `${API_URI}/v1/user/current/`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (res.ok) {
        const user = await res.json();
        setAuthObject((old) => {
          const result = _.cloneDeep(old);
          result.token = token;
          result.user = user;
          return result;
        });
      } else {
        const errs = {
          status: res.status,
          message: res.statusText,
        };
        setAuthObject((old) => {
          const result = _.cloneDeep(old);
          result.authErrors = errs;
          return result;
        });
      }
    };

    if (token) {
      fetchUser();
    }
  }, [token]);


  return (
    <AuthContext.Provider value={authObject}>
      {children}
    </AuthContext.Provider>
  );
}

AuthenticatedApp.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.node, PropTypes.arrayOf(PropTypes.node)],
  ),
};
