import React, {useContext} from 'react';
import {AuthContext} from '../AuthContext';
import {API_URI} from '../globals';
import {styled} from '@mui/system';
import PropTypes from 'prop-types';

function Logo({className}) {
  const {token} = useContext(AuthContext);
  return (
    <img
      className={className}
      src={`${API_URI}/v1/user/current/organization/logo/?token=${token}`}
      onError={({currentTarget}) => {
        currentTarget.onerror = null;
        currentTarget.src = '/transformers.svg';
      }}/>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default styled(Logo)({});
