export const API_URI = (
  process.env.NODE_ENV === 'development' ?
    'http://localhost:8000' :
    process.env.REACT_APP_API_BASE_URL
);

export const AGENT_URI = (
  process.env.NODE_ENV === 'development' ?
    'http://localhost:8001' :
    process.env.REACT_APP_AGENTS_BASE_URL
);

