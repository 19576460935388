import _ from 'lodash';
import {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../AuthContext';

export const getAuthenticatedFetch = (token, otherHeaders) => {
  if (!token) {
    throw new Error('Cannot use authenticatedFetch without a token.');
  }

  const baseHeaders = {
    ...otherHeaders,
    'Authorization': `Token ${token}`,
  };

  const baseOptions = {
    headers: baseHeaders,
  };

  return async (url, options) => {
    const mergedOptions = _.merge(baseOptions, options);

    return await fetch(url, mergedOptions);
  };
};
export const useFetchGet = (defaultUrl, initialData, requiresAuth = false) => {
  const auth = useContext(AuthContext);
  const [url, setUrl] = useState(defaultUrl);
  const [data, setData] = useState(initialData);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    const controller = new AbortController();
    const inner = async () => {
      let client;
      if (requiresAuth) {
        client = getAuthenticatedFetch(auth.token);
      } else {
        client = fetch;
      }

      setIsPending(true);
      const res = await client(url, {signal: controller.signal});
      if (res.ok) {
        const data = await res.json();
        setIsPending(false);
        setData(data);
        setError(null);
      } else {
        setIsPending(false);
        setData(null);
        setError({
          status: res.status,
          message: res.statusText,
        });
      }
    };

    if (url) {
      inner();
    }

    return () => {
      controller.abort(0);
    };
  }, [url]);

  const clear = () => {
    setData(initialData);
    setError(null);
  };

  return {
    data,
    setData,
    isPending,
    error,
    setUrl,
    clear,
  };
};
const useFetchPostOrPut = (initialData, requiresAuth = false, method = 'POST') => {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(initialData);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [numberOfRunningReq, setNumberOfRunningReq] = useState(0);


  useEffect(() => {
    setIsPending(numberOfRunningReq > 0);
  }, [numberOfRunningReq]);


  const send = async (url, body) => {
    let client;
    if (requiresAuth) {
      client = getAuthenticatedFetch(auth.token);
    } else {
      client = fetch;
    }

    setNumberOfRunningReq((previous) => previous + 1);
    const res = await client(
      url,
      {
        method: method,
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      },
    );
    if (res.ok) {
      const data = await res.json();
      setNumberOfRunningReq((previous) => previous - 1);
      setData(data);
      setError(null);

      return data;
    } else {
      setNumberOfRunningReq((previous) => previous - 1);
      setData(null);
      setError({
        status: res.status,
        message: res.statusText,
      });

      return null;
    }
  };

  const clear = () => {
    setData(initialData);
    setError(null);
  };

  return {
    send,
    data,
    setData,
    isPending,
    error,
    clear,
  };
};
export const useFetchPost = (initialData, requiresAuth = false) => {
  return useFetchPostOrPut(initialData, requiresAuth, 'POST');
};
export const useFetchPut = (initialData, requiresAuth = false) => {
  return useFetchPostOrPut(initialData, requiresAuth, 'PUT');
};

export const useFetchPatch = (initialData, requiresAuth = false) => {
  return useFetchPostOrPut(initialData, requiresAuth, 'PATCH');
};
