import React, {useEffect, useState} from 'react';
import {Box, styled} from '@mui/system';
import IconButton from '@mui/material/IconButton';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import {useFetchPatch} from '../hooks/api';
import {API_URI} from '../globals';
import PropTypes from 'prop-types';

function Feedback({
  conversationId,
  disabled,
  className,
}) {
  const [vote, setVote] = useState(undefined);
  const {
    send,
  } = useFetchPatch(undefined, true);

  useEffect(() => {
    if (vote) {
      send(
        `${API_URI}/v1/conversation/${conversationId}/`,
        {vote},
      );
    }
  }, [vote]);

  return (
    <Box className={className}>
      <IconButton
        color={vote == 'NEGATIVE' ? 'error': undefined}
        onClick={() => setVote('NEGATIVE')}
        disabled={disabled}>
        <ThumbDownOutlinedIcon></ThumbDownOutlinedIcon>
      </IconButton>
      <IconButton
        color={vote == 'POSITIVE' ? 'success': undefined}
        onClick={() => setVote('POSITIVE')}
        disabled={disabled}>
        <ThumbUpOutlinedIcon></ThumbUpOutlinedIcon>
      </IconButton>
    </Box>
  );
}

Feedback.propTypes = {
  conversationId: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default styled(Feedback)({});
