import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import {useContext, useEffect} from 'react';
import {AuthContext} from './AuthContext';
import PropTypes from 'prop-types';

export default function NeedsAuth({children}) {
  const location = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!auth.token) {
      location('/login');
    }
  }, [auth]);

  return <>
    {auth.token && auth.user && children}
  </>;
}

NeedsAuth.propTypes = {
  children: PropTypes.node,
};
