import React from 'react';
import PropTypes from 'prop-types';
import Card from '../ui/Card';
import {CardActions, CardContent, Chip, Tooltip} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Box, styled} from '@mui/system';
import OpenDocumentButton from './OpenDocumentButton';

function GenericSource({
  fragment,
  className,
  metadataRenderer,
}) {
  return (
    <Card className={className}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <CardContent
          sx={{
            flexGrow: 1,
          }}>
          <Typography
            variant="h5"
            sx={{
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
            }}
            color="primary"
          >{fragment?.source?.name}</Typography>
          <Box>
            {metadataRenderer && metadataRenderer(fragment?.metadata)}
          </Box>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: 'space-between',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: (t) => t.spacing(1),
            }}>
            <Chip
              label={fragment?.source?.type}
              color="primary"
              sx={{
                maxWidth: '130px',
              }}></Chip>
            {fragment?.source?.collection &&
              <Tooltip title={fragment?.source?.collection?.description}>
                <Chip
                  label={fragment?.source?.collection?.name}
                  color="secondary"
                  sx={{
                    maxWidth: '130px',
                  }}></Chip>
              </Tooltip>
            }
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: (t) => t.spacing(1),
            }}>
            <OpenDocumentButton source={fragment?.source} />
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
}

GenericSource.propTypes = {
  fragment: PropTypes.object.isRequired,
  className: PropTypes.string,
  metadataRenderer: PropTypes.func,
};

export default styled(GenericSource)({});
